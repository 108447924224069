/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        //***********
        // LOAD
        // **********
        $(window).load(function () {
          $("#spinner").fadeOut(600);

        //***********
        // COOKIES 
        // **********
        function pnCookies() {
          var cookieName = 'pn-cookie-eu';
          var cookie = Cookies.get(cookieName);
          var div = document.querySelector('.js-pn-cookies');
          var button = document.querySelector('.js-pn-cookies-close');

          // Check if user has accepted cookie
          if (!cookie || cookie !== 'true') {
            div.classList.remove('is-translated');

            button.addEventListener('click', function() {
              div.classList.add('is-translated');

              Cookies.set(cookieName, 'true', { expires: 365 });
            });
          }
        }

        // Load Cookie.js library if not present
        if (window.Cookies) {
          pnCookies();
        } else {
          var s = document.createElement('script');
          s.src = "https://cdn.jsdelivr.net/npm/js-cookie@2/src/js.cookie.min.js";
          
          s.addEventListener('load', pnCookies)
          document.head.appendChild(s);
        }
        

          //---------
          // BTN bas de page fixed
          //---------
          setTimeout(function() {
              if(!$('.page-contact').length){
                $('.btn-bottom-fixed').addClass('visible');
              }
          }, 2000);
        });

        window.cache = {
          '$window': $(window),
          '$document': $(document),
          '$body': $('body')
        };

        // prevent default menu
        $('.prevent-default>a').click(function(e){
          e.preventDefault();
        }); 


        //***********
        // GENERAL - RESIZE 
        // **********

        //animation du scroll
        $('a.anchor').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 500);
                    return false;
                }
            }
        });

        $('[data-js="search-btn"]').click(function(){
          var $thisInput = $(this).parent().find('.search-input');

          if($thisInput.val() === '') {
            $thisInput.toggleClass('active');
            setTimeout(function(){
                $("#txtSearch").focus();
            }, 500);

            return false;
          }

        });

        var windowHeight = $(window).height();
        var windowWidth = $(window).width();

        $(window).resize(function() {
          windowHeight = $(window).height();
          windowWidth = $(window).width();

          if (windowWidth > 991) {
            $('.bg-bois-deco-fixed').height(windowHeight);
            $('.big-banner').height(windowHeight - $('.main-nav').outerHeight());
          } else {
            $('.bg-bois-deco-fixed').height('auto');
            $('.big-banner').height('auto');
          }

          if(windowWidth > 1024) {
            $('.banner').parallax("50%", 0.1);
          } else {
            $('.banner').parallax("50%", 0);
          }

        });

         $(window).trigger('resize'); 

          //***************
          // FORM
          //***************
          $('input[type=file]').customFile();

         //***********
        // SCROLL
        // **********
        var footerHeight = $('footer').outerHeight() + 30;

        $(window).scroll(function() {
          if($(window).scrollTop() + $(window).height() > $(document).height() - footerHeight) {
            $('.btn-bottom-fixed').css('bottom',footerHeight);
          } else {
            $('.btn-bottom-fixed').css('bottom','');
          }
        });

        //---------
        // 404
        //---------
        if($('.page-404').length > 0){

            $('.fit-screen').height(windowHeight);

            $(window).resize(function(){
                var windowHeight = $(window).height();
                $('.fit-screen').height(windowHeight);
            });
        }

        //---------
        // Header
        //---------
        var $header = $('[data-module="header"]');
        
        if ($header.length > 0) {
          new Header();
        }

        // Home
        //---------
        var $home = $('[data-module="home"]');

        if ($home.length > 0) {
          new Home();
        }

        //---------
        // Meet our team
        //---------
        var $contact = $('[data-module="contact"]');
        
        if ($contact.length > 0) {
          new Contact();
        }

          //---------
          // Join our team
          //---------
          var $joinTeam = $('[data-module="join-team"]');

          if ($joinTeam.length > 0) {
              new JoinTeam();
          }

        //---------
        // Manufacturing
        //---------
        var $manufacturing = $('.js-manufacturing');

        
        if ($manufacturing.length > 0) {
          new Manufacturing();
        }

        //---------
        // Product
        //---------
        var $products = $('[data-module="products"]');

        
        if ($products.length > 0) {
          new Products();
        }


        //---------
        // Template 2 - About
        //---------
        var $template2 = $('[data-module="template2"]');

        
        if ($template2.length > 0) {
          new Template2();
        }

        //---------
        // Family
        //---------
        var $family = $('[data-module="family"]');

        if ($family.length > 0) {
          new Family();
        }

        //---------
        // Approach
        //---------
        var $approach = $('[data-module="approach"]');
        if ($approach.length > 0) {
          new Approach();
        }

         //---------
        // Event
        //---------
        var $event = $('[data-module="event"]');
        if ($event.length > 0) {
          new Event();
        }

        //---------
        // Disrupters
        //---------
        var $disrupters = $('[data-module="disrupters"]');
        if ($disrupters.length > 0) {
          new Disrupters();
        }
        // Animations - FadeIn
        //---------
        // One element : Add Class Animate
        function addAnimateClass(element, offset) {
            $(element).waypoint(function(direction) {
              // console.log($(this));
              $(this).css({'background': 'red'});

              $(element).addClass('animate');
              this.destroy();
            }, {
              offset: offset
            });
        }

        addAnimateClass('.logos-list', '80%');
        addAnimateClass('.section-principles', '75%');

        // List of elements : Add Class Animate for each element
        function progressiveAddingAnimateClass(element, offset) {
          var continuousElements = $(element);
          for (var i = 0; i < continuousElements.length; i++) {
            new Waypoint({
              element: continuousElements[i],
              handler: function(direction) {
                this.element.className += " animate";

                this.destroy();
              },
                offset: offset
            });
          }
        }

        progressiveAddingAnimateClass('.bloc-text-medias', '70%');
        progressiveAddingAnimateClass('.job-advantage', '75%');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_page_join_team': {
      init: function() {
        // JavaScript to be fired on the joint team us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_service': {
      init: function() {
        if(typeof onLoadAnchorService !== "undefined"){
          // console.log($("#service-" + onLoadAnchorService));
          $('html, body').animate({scrollTop: $("#service-" + onLoadAnchorService).offset().top -100 }, 'slow');
            // $("#service-" + onLoadAnchorService).scrolltop();
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
