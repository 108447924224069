(function($) {

  'use strict';

  function Event() {

    var self = this,
      $form = null,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    function onResize() {

    }

    /**
     * INIT FORM
     */
    var initForm = function(){
      $form = $('#formEvent');

      $form.submit(function(e){
        e.preventDefault();

        $form.find(".txt-notification").hide();

        var error = 0;
        $form.find("[data-required]").each(function(){
          $(this).removeClass("data-required");
          if($(this).val() === ""){
            $("#txtFormError").slideDown(300);
            $(this).addClass("data-required");
            error=1;
          }
        });

        //Si pas erreur,  on envoi
        if(error === 0){


          $form.closest('.modal-dialog').block({
            message: $("#spinner-ajax"),
            overlayCSS: {
              background: '#fff',
              opacity: 0.6
            }
          });

          var sData = $(this).serialize();
          $.ajax({
            method: "POST",
            url: ajaxurl,
            dataType : "json",
            data:  sData
          })
            .done(function( data ) {
              if(data.status === "success") {
                $form.closest('.modal-dialog').unblock();
                $("#txtFormSuccess").show();
                $form.find("[data-required]").val("");
              }
            });
        }


      });
    };


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

      var lang = document.getElementsByTagName("html")[0].getAttribute("lang");

      if (lang == "fr-CA") {
        $('.js-main-nav').remove();
        $('.js-toggle-menu').remove();

      }

      $('.btn-bottom-fixed').remove();


      initForm();

      //*************
      window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Event = Event;

}(jQuery));
