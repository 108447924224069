(function ($) {

    'use strict';

    function Products() {

        var self = this,
            $teamSlider = null,
            $addressCol = null,
            cityPoint = null,
            $cityName = null,
            $coordonneesBlock = null,
            $productContainer = null,
            options = {
                'example': null
            };

        /**
         * Generic resize
         */
        // function onResize() {

        // }


        /**
         * Public constructor
         * @param  {Object} opts Options
         */
        self.initialize = function (opts) {
            //extends options
            for (var i in opts) {
                options[i] = opts[i];
            }

            var currentFilterClick;
            var activeFilterCategories = [];

            var $filtersCategories = $('.category-nav a');
            var $filterElem = $('.category-nav a');
            var $productContainer = $('#products-container');

            var currentIdClick;
            initSingleProduct();
            
            function filterProducts() {
                // get id click


                // get current filter
                activeFilterCategories = [];
                $filtersCategories.each(function () {
                    if ($(this).hasClass("active")) {
                        activeFilterCategories.push($(this).data("id"));
                    }
                });


                $('.one-product').each(function (e) {
                    $(this).fadeIn(300);

                    if (activeFilterCategories.length) {
                        var curCategories = $(this).data('id');

                        if (activeFilterCategories.indexOf(curCategories) === -1) {
                            $(this).hide();
                        }
                    }
                });

            }

            $filterElem.click(function (e) {
                e.preventDefault();
                $filterElem.removeClass("active");
                $(this).addClass("active");

                var currentIdClick = $(this).data('id');
                var currentLink = $(this).attr('href');

                // breadcumbs
                var nameCat = $(this).attr('data-name');
                $('.breadcrumb li:first-child a').html(nameCat);
                $('.breadcrumb li:first-child a').attr("href", currentLink);
                $('.breadcrumb li:first-child a').attr("data-id", currentIdClick);
                $('.breadcrumb li:first-child a').attr("data-name", nameCat);
                $('.breadcrumb li:eq(1)').html("");


                $productContainer.html('<div class="spinner-ajax"><div class="loader-circle"></div> <div class="loader-line-mask"> <div class="loader-line"></div> </div></div>');

                // call ajax
                var pData = {"action": "search_products", "type": "cat", "id": currentIdClick};
                var ajaxReq = JSON.stringify(pData);
                window.history.pushState(ajaxReq, nameCat, currentLink);

                $.ajax({
                    method: "POST",
                    url: ajaxurl,
                    dataType: "json",
                    data: pData
                })
                    .done(function (data) {
                        if (data.status === "success") {
                            $productContainer.html(data.html);
                        }

                    });

                // scroll anchor
                $('html, body').animate({
                    scrollTop: $('#products-container').offset().top - 100
                }, 500);

            });


            // Recherche Produits
            $("#formSearchProducts").submit(function (e) {
                e.preventDefault();
                var strSearch = $(this).find('input[name="search"]').val();

                $productContainer.html('<div class="spinner-ajax"><div class="loader-circle"></div> <div class="loader-line-mask"> <div class="loader-line"></div> </div></div>');

                // Reset
                $('.breadcrumb li').html("");
                $('.breadcrumb li:eq(0)').html("<a href='' class='ajax-link' data-type='cat'></a>");
                $filterElem.removeClass("active");

                // call ajax
                var pData = {"action": "search_products", "type": "search", "search": strSearch};

                $.ajax({
                    method: "POST",
                    url: ajaxurl,
                    dataType: "json",
                    data: pData
                })
                    .done(function (data) {
                        if (data.status === "success") {
                            $productContainer.html(data.html);
                        }

                    });

            });
            $("#formSearchProducts img").click( function(){
                $("#formSearchProducts").submit();
            } );

            //
            //      AJAX NAVIGATION
            //
            $('[data-module="products"]').on("click", ".ajax-link", function (e) {
                e.preventDefault();
                var prodName = $(this).attr("data-name");
                var breadcrumbCat = $filterElem.filter(".active").html();
                var id = ( $(this).attr("data-id") );
                var type = ( $(this).attr("data-type") );

                $productContainer.html('<div class="spinner-ajax"><div class="loader-circle"></div> <div class="loader-line-mask"> <div class="loader-line"></div> </div></div>');

                // Update Breadcrumb
                if( type === "cat" ){
                    $('.breadcrumb li:eq(1)').html("");

                }else{
                    $('.breadcrumb li:eq(1)').html(prodName);
                }

                // call ajax
                var pData = {"action": "search_products", "type": type, "id": id};
                var currentLink = $(this).attr('href');
                var ajaxReq = pData;
                ajaxReq["name"] = prodName;
                ajaxReq["breadcrumbCat"] = breadcrumbCat;
                ajaxReq = JSON.stringify(pData);
                window.history.pushState(ajaxReq, prodName, currentLink);

                $.ajax({
                    method: "POST",
                    url: ajaxurl,
                    dataType: "json",
                    data: pData
                })
                    .done(function (data) {
                        if (data.status === "success") {
                            $productContainer.html(data.html);

                            initSingleProduct();

                        }
                    });

            });


            //
            //      AJAX NAVIGATION
            //
            var $arrowModal = $('[data-js="modal-navigation"]');
            $arrowModal.click(function(){

                var direction = $(this).data("direction");
                
                // Find Current Img or take First
                var $cur = $(".viewer-list .current-clicked");

                if($cur.length === 0){
                    $(".viewer-list .slick-slide.slick-active:eq(0)").addClass("current-clicked");   
                    $cur = $(".viewer-list .current-clicked");                    
                }

                if(direction === "next"){

                    var $next = $cur.next();
                    var nextInnerImg = $next.find('.inner').data('url');

                    $('#js-image-modal-zoom').attr('src', nextInnerImg);
                    $('.viewer-list .slick-slide').removeClass("current-clicked");

                    $next.addClass("current-clicked");
                }

                if(direction === "prev"){
                    var $prev = $cur.prev();
                    var prevInnerImg = $prev.find('.inner').data('url');

                    $('#js-image-modal-zoom').attr('src', prevInnerImg);
                    $('.viewer-list .slick-slide').removeClass("current-clicked");
                    
                    $prev.addClass("current-clicked");
                }
            });


            //
            //  INIT Single Product
            //
            function initSingleProduct() {
                $('#tabs a').click(function (e) {
                    e.preventDefault();
                    $('#tabs a').removeClass('active');
                });

                $('.viewer-list .inner').click(function (e) {
                    $('.viewer-list .slick-slide').removeClass("current-clicked");                  
                    $(this).parent().addClass("current-clicked");
                    $('.viewer-main-img').attr('src', $(this).data('url'));
                });

                $('.viewer-list').slick({
                  infinite: true,
                  speed: 300,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  responsive: [
                    {
                      breakpoint: 600,
                      settings: {
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true
                      }
                    }
                  ]
                });

            }

            // single product zoom modal
            $('[data-module="products"]').on("click", ".main-img-container", function (e) {
                $('#js-zoom-product').modal('show');
                var urlimg = $('.viewer-main-img').attr('src');
                $('#js-image-modal-zoom').attr('src',urlimg);
            });

            // ancre quand clic sur un item du menu
            if( $('.tax-product_categories').length ){
                $("html, body").animate({ scrollTop: $('#anchor-products').offset().top - 100 }, 600);
            }

            // menu catégorie responsive
            $('.js-plus-btn').click(function(){
                $(this).toggleClass('active');
                $('.js-category-nav').toggleClass('opened');
            });

            //
            //  AJAX NAVIGATION BROWSER HISTORY
            //
            window.addEventListener('popstate', function (e) {
                // e.state is equal to the data-attribute of the last image we clicked
                console.log(e.state);
                if (e.state == null) {
                    window.location.href = location.href;
                } else {

                    var pData = JSON.parse(e.state);
                    $productContainer.html('<div class="spinner-ajax"><div class="loader-circle"></div> <div class="loader-line-mask"> <div class="loader-line"></div> </div></div>');

                    // console.log(e);

                    // Change  ACtive cat
                    $filterElem.removeClass("active");
                    if (pData.type === "cat") {
                        $filterElem.filter("[data-id='" + pData.cat + "']").addClass("active");

                        // breadcumbs
                        var nameCat = $filterElem.filter("[data-id='" + pData.cat + "']").html();
                        $('.breadcrumb li:first-child').html(nameCat);
                        $('.breadcrumb li:eq(1)').html("");

                    }

                    // Change  ACtive cat, breadcrumb
                    if (pData.type === "id") {
                        $('.breadcrumb li:first-child').html(pData.breadcrumbCat);
                        $('.breadcrumb li:eq(1)').html(pData.name);
                    }

                    // Change Breadcrumb

                    $.ajax({
                        method: "POST",
                        url: ajaxurl,
                        dataType: "json",
                        data: pData
                    })
                        .done(function (data) {
                            if (data.status === "success") {
                                $productContainer.html(data.html);
                                initSingleProduct();
                            }

                        });

                }


            });


        };

        self.initialize.apply(self, arguments);
        return self;

    }

    /* Namespace declaration */
    window.Products = Products;

}(jQuery));
