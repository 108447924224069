(function($) {

  'use strict';

  function Approach() {

    var self = this,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    function onResize() {

    }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

      // Caching
    
      // carousel
      $('.approach-carousel').slick({
        infinite: true,
        dots: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false
            }
          }
        ]
      });


      // Animations
      function addAnimateClass(element, offset) {
          $(element).waypoint(function(direction) {
            $(element).addClass('animate');
            this.destroy();
          }, {
            offset: offset
          });
      }

      addAnimateClass('.approach-values', '75%');
      addAnimateClass('.approach-list', '70%');


      //*************
      window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Approach = Approach;

}(jQuery));
