(function($) {

  'use strict';

  function Disrupters() {

    var self = this,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    function onResize() {

    }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

      // Caching

      $('.speed-1').dynamo({
          delay: 4200
      });

      $('.speed-2').dynamo({
          delay: 4600
      });

      $('.speed-3').dynamo({
          delay: 5000
      });

      $('.speed-4').dynamo({
          delay: 5400
      });

      $('.speed-5').dynamo({
          delay: 5800
      });

      $('.speed-6').dynamo({
          delay: 6200
      });

      $('.speed-7').dynamo({
          delay: 6600
      });

      // changement des bg
      var colors = ['gre-light','blue','blue-dark'];
      var colorsIndex = -1;

      var delays = ['5s','5.5s','6s','6.5s'];
      var delay = 5000;

      function changeClass() {
        var elem = $('[data-selector="element"]');

        elem.each(function () {

          if ( colorsIndex >= 0 ) {
            $(this).removeClass(colors[colorsIndex]);
          }

          colorsIndex = colors[Math.floor(Math.random() * colors.length)];

          delay = delays[Math.floor(Math.random() * delays.length)];

          $(this).css('transition-delay',delay);
          $(this).removeClass('gre-light');
          $(this).removeClass('blue');
          $(this).removeClass('blue-dark');
          $(this).addClass(colorsIndex);

        });
      }

      changeClass();
      setInterval(changeClass, 3000);

      // ouverture des box au clic sur le plus
      var divcontent;
      $('div.blue-black').click(function(){

        divcontent = $(this).find('.content-box');

        if (!divcontent.hasClass('opened')){
          $('.content-box').removeClass('opened');
          $(this).find('.content-box').addClass('opened');
          $('span.plus').removeClass('opened');
          $(this).find('span.plus').addClass('opened');
        } else {
          $(this).find('span.plus').removeClass('opened');
          $(this).find('.content-box').removeClass('opened');
        }
        

      });

      //*************
      window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Disrupters = Disrupters;

}(jQuery));
