(function($) {

  'use strict';

  function Family() {

    var self = this,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    function onResize() {

    }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

      // Caching


      function addAnimateClass(element) {
          $(element).waypoint(function(direction) {
            if(direction === 'down') {
              $(element).addClass('animate');
            } else {
              $(element).removeClass('animate');
            }
          }, {
            offset: '75%'
          });
      }

      $('.fact-screen').waypoint(function(direction) {

          $('.anim-number').animateNumber(
            {
              number: $('.anim-number').attr('data-number'),

              // optional custom step function
              // using here to keep '%' sign after number
              numberStep: function(now, tween) {
                var floored_number = Math.floor(now),
                    target = $(tween.elem);

                target.text(floored_number);
              }
            },
            1000
          );

          this.destroy();

      }, {
        offset: '75%'
      });


      addAnimateClass('.facts-crocs-wrapper');
      addAnimateClass('.facts-3-wrapper');
      addAnimateClass('.fact-girls');
      addAnimateClass('.fact-dog-img');
      addAnimateClass('.facts-6-wrapper');
      addAnimateClass('.facts-7-wrapper');
      addAnimateClass('.fact-tornado-img');
      addAnimateClass('.fact-sports');
      addAnimateClass('.facts-10-wrapper');


      //*************
      window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Family = Family;

}(jQuery));
